var isTouch;
function touchCheck() {
    if (Modernizr.touchevents) {
        isTouch = true;
    } else {
        isTouch = false;
    }
}
touchCheck();

var News = {
    selectClassName: ".js-select",
    init: function() {
        var self = this;
        $(self.selectClassName).on("change", function () {
            var oldSelection = $(this).find(":disabled").val(); // Get current selection before it changes
            var currentLocation = location.href;                // Get current URL
            var lastChar = currentLocation.substr(-1);          // Get last character of current URL

            // Check if oldSelection exists.
            if (oldSelection != null && oldSelection !== '') {

                // Extract the oldSelection including the /
                currentLocation = currentLocation.split("/" + oldSelection)[0];
            }

            /*
            if (isLastNode === "yes") {
                console.log("isLastNode true", isLastNode);
                location.href = $(this).val();
            } else if (isLastNode === "no" && lastChar === "/") {
                location.href = $(this).val();
            } else if (isLastNode === "no" && /^\d+$/.test(lastChar)) {
                location.href = $(this).val();
            } else */

            // if the current page is not the last node & the last character of the current url is not / then we will change the location.href to the current url + / + the selected value
            if (isLastNode === "no" && lastChar !== "/") {
                location.href = currentLocation + "/" + $(this).val();
                return;
            }
            location.href = $(this).val();
            
        });
    }
};

var Navigation = {
    toggleClassName: ".js-nav-level-toggle",
    toggleDataAttr: "navLevel",
    navLevelClassName: ".nav-level",
    navTitleClassName: ".nav-title",
    levelClassName: ".js-level-",
    activeClassName: 'is-active',
    searchForm: ".js-nav-searchform",
    searchInput: ".js-nav-searchinput",
    init: function() {
        var self = this;

        $(self.toggleClassName).on("click", function (e) {
            var $this = $(this);
            var $target;
            var isHamburger = $this.hasClass('nav-toggle');

            if (isTouch || isHamburger) {
                e.preventDefault();
                var dataTarget = $this.data(self.toggleDataAttr);

                // If clicking the hamburger / main menu, change nav-title (and close everything)
                if (isHamburger) {
                    var $title = $(self.navTitleClassName, $(this));

                    if ($this.hasClass(self.activeClassName)) {
                        // Close all
                        var $allActiveNavLevels = $(self.navLevelClassName);
                        $allActiveNavLevels.removeClass(self.activeClassName);

                        var $allToggles = $(self.toggleClassName);
                        $allToggles.removeClass(self.activeClassName);
                    
                        $title.html("Menu");
                    } else {
                        // Open first
                        $target = $(self.levelClassName + dataTarget);
                        $this.addClass(self.activeClassName);
                        $target.addClass(self.activeClassName);

                        $title.html("Sluiten");
                    }
                
                } else {
                    $target = $(self.levelClassName + dataTarget, $this.parent());
                    var wasActive = $target.hasClass(self.activeClassName);

                    // If opening a submenu, close all submenus of the same level
                    var allFromLevel = $(self.levelClassName + dataTarget);
                    allFromLevel.removeClass(self.activeClassName);

                    var allTogglesFromSameLevel = $(self.toggleClassName);
                    allTogglesFromSameLevel.each(function(el) {
                        var $toggleFromSameLevel = $(allTogglesFromSameLevel[el]);
                        if ($toggleFromSameLevel.data(self.toggleDataAttr) === dataTarget) {
                            $toggleFromSameLevel.removeClass(self.activeClassName);
                        }
                    });

                    if (!wasActive) {
                        $this.toggleClass(self.activeClassName);
                        $target.toggleClass(self.activeClassName);
                    }
                }
            }
        });
        $(self.searchForm).on("submit", function(e) {
            e.preventDefault();
            
            location.href = "/search/?query=" + $(this).find(self.searchInput).val();

        });
    }
};

var Newsletter = {
    className: ".js-newsletter",
    largeForm: "form__newsletter-large",
    smallForm: "form__newsletter-small",
    formSubmitButton: ".js-newsletter-submit",
    formErrorsClassName: ".js-form-errors",
    hasErrorClassName: "has-errors",
    hiddenClassName: "is-hidden",
    notVisibleClass: "not-visible",
    titleClassName: ".js-title",
    titleThanksClassName: ".js-title-thanks",
    init: function() {
        var self = this;

        $(self.formSubmitButton).on("click", function (e) {
            e.preventDefault();
            var form = $(this).closest(".form").attr("id");
            var $formId = $("#" + form);

            // validate selected form
            $formId.parsley({
                errorClass: 'has-error',
                successClass: 'has-success',
                //errorsContainer: $(this).find(self.formErrorsClassName),
                // Group form errors
                errorsContainer: function (field) {
                    return $($formId).find(self.formErrorsClassName);
                },
                errorsWrapper: '<span class="form-field-errors"></span>',
                errorTemplate: "<em></em>"
            }).validate();

            if ($formId.parsley().isValid()) {
                $formId.find(self.formErrorsClassName).addClass(self.hiddenClassName);
                self.handleSubmit($formId);
            } else {
                $formId.find(self.formErrorsClassName).removeClass(self.hiddenClassName);
            }

        }); 
    },
    handleSubmit: function($form) {
        var self = this;
        var parent = $form.parent();
        var serializedData = $form.serialize();
        console.log(serializedData);

        // If success
        if ($form.hasClass(self.smallForm)) {
            $form.addClass(self.hiddenClassName);
            parent.find(self.titleClassName).addClass(self.hiddenClassName);
            parent.find(self.titleThanksClassName).removeClass(self.notVisibleClass);
        } else {
        $form.addClass(self.notVisibleClass);
            parent.find(self.titleClassName).addClass(self.notVisibleClass);
            parent.find(self.titleThanksClassName).removeClass(self.notVisibleClass);
        }
    }
};

var Contact = {
    className: ".js-contact-form",
    submitClassName: ".js-submit-contact",
    formErrorsClassName: ".js-form-errors",
    hasErrorsClassName: "has-errors",
    hiddenClassName: "is-hidden",
    init: function() {
        var self = this;

        // Validate on submit
        $(self.submitClassName).on("click", function (e) {
            // prevent submit before validation
            e.preventDefault();

            // validate contact form
            $(self.className).parsley({
                errorClass: "has-error",
                successClass: "has-success",
                //errorsContainer: function(field) {
                //    return $(self.className).find(self.formErrorsClassName);
                //},
                errorsWrapper: '<span class="form-field-errors absolute"></span>',
                errorTemplate: "<em></em>"
            }).validate();

            if ($(self.className).parsley().isValid()) {
                console.log("valid");
                self.handleSubmit(self.className);
            } else {
                console.log("invalid");
            }
        });
    },
    handleSubmit: function(form) {
        var self = this;
        //var serializedData = $(form).serialize();
        //console.log(serializedData);
        $(form).submit();

    }
};

var Events = {
    className: ".js-event-form",
    submitClassName: ".js-submit-event",
    formErrorsClassName: ".js-form-errors",
    hasErrorsClassName: "has-errors",
    hiddenClassName: "is-hidden",
    init: function () {
        var self = this;

        // Validate on submit
        $(self.submitClassName).on("click", function (e) {
            // prevent submit before validation
            e.preventDefault();

            // validate contact form
            $(self.className).parsley({
                errorClass: "has-error",
                successClass: "has-success",
                //errorsContainer: function(field) {
                //    return $(self.className).find(self.formErrorsClassName);
                //},
                errorsWrapper: '<span class="form-field-errors absolute"></span>',
                errorTemplate: "<em></em>"
            }).validate();

            if ($(self.className).parsley().isValid()) {
                console.log("valid");
                self.handleSubmit(self.className);
            } else {
                console.log("invalid");
            }
        });
    },
    handleSubmit: function (form) {
        var self = this;
        var serializedData = $(form).serialize();
        console.log(serializedData);
        $(form).submit();

    }
};

var Petition = {
    className: ".js-petition-form",
    submitClassName: ".js-submit-petition",
    formErrorsClassName: ".js-form-errors",
    hasErrorsClassName: "has-errors",
    hiddenClassName: "is-hidden",
    init: function () {
        var self = this;

        // Validate on submit
        $(self.submitClassName).on("click", function (e) {
            // prevent submit before validation
            e.preventDefault();

            // validate contact form
            $(self.className).parsley({
                errorClass: "has-error",
                successClass: "has-success",
                //errorsContainer: function(field) {
                //    return $(self.className).find(self.formErrorsClassName);
                //},
                errorsWrapper: '<span class="form-field-errors absolute"></span>',
                errorTemplate: "<em></em>"
            }).validate();

            if ($(self.className).parsley().isValid()) {
                console.log("valid");
                self.handleSubmit(self.className);
            } else {
                console.log("invalid");
            }
        });
    },
    handleSubmit: function (form) {
        var self = this;
        var serializedData = $(form).serialize();
        console.log(serializedData);
        $(form).submit();

    }
};

var Socials = {
    className: 'js-socials',
    buttonClassName: '.js-socials-button',
    shareWindowWidth: 550,
    shareWindowHeight: 285,
    init: function () {
        var self = this;
        $(document).on('click', self.buttonClassName, self.handleClick.bind(self));
    },
    handleClick: function (event) {
        var self = this;
        var $target = $(event.currentTarget);

        var item = {
            network: $target.data('social-network'),
            name: $target.data('social-name'),
            link: $target.data('social-link'),
            description: $target.data('social-description')
        };

        switch (item.network) {
        case 'twitter':
            self.openDialogWindow(item);
            break;
        case 'facebook':
            self.openDialogWindow(item);
            break;
        case 'linkedin':
            self.openDialogWindow(item);
            break;
        }

    },
    openDialogWindow: function (item) {
        var self = this;
        var shareUrl = self.getShareUrl(item);
        var y = window.top.outerHeight / 2 + window.top.screenY - (self.shareWindowHeight / 2);
        var x = window.top.outerWidth / 2 + window.top.screenX - (self.shareWindowWidth / 2);
        window.open(shareUrl, 'Share', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=' + self.shareWindowHeight + ', width=' + self.shareWindowWidth + ', top=' + y + ', left=' + x);
    },
    getShareUrl: function (item) {
        var shareUrl;

        switch (item.network) {
        case 'twitter':
                shareUrl = 'http://twitter.com/share?text=' + item.name + ' ' + item.description + '&url=' + item.link;
            break;
        case 'facebook':
                shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + item.link;
            break;
        case 'linkedin':
                shareUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=' + item.link + '&title=' + item.name + '&summary=' + item.description;
            break;
        }

        return shareUrl;
    }
};

var Faq = {
    toggleQuestion: '.js-question',
    questionIcon: '.js-icon',
    answerClassName: '.js-answer',
    hiddenClassName: 'is-hidden',
    faqFilter: ".js-faq-filter",
    faqGroupQuestion: ".faq__group-question",
    init: function() {
        var self = this;
        $(document).on("click", self.toggleQuestion, self.handleClick.bind(self));
        $(document).on("change", self.faqFilter, self.handleFilter.bind(self));
    },
    handleClick: function(event) {
        var self = this;
        $(event.currentTarget).toggleClass('is-active');
        $(event.currentTarget).next().slideToggle();
    },
    handleFilter: function(event) {
        var self = this;
        var target = event.target.value;
        target = target.replace(/\s+/g, "-");
        var targetId = document.getElementById(target);
        if (target === "all") {
            $(self.faqGroupQuestion).addClass("is-visible");
        } else {
            $(self.faqGroupQuestion).removeClass("is-visible");
            $(targetId).addClass("is-visible");
        }

    }
};

var Article = {
    whatsappBtn: '.js-article-whatsapp',
    printBtn: '.js-article-print',
    facebookBtn: '.js-article-facebook',
    twitterBtn: '.js-article-twitter',
    linkedinBtn: '.js-article-linkedin',
    init: function () {
        var self = this;
        $(document).on('click', self.printBtn, self.handlePrint.bind(self));
        $(document).on('click', self.whatsappBtn, self.handleWhatsapp.bind(self));
        $(document).on('click', self.facebookBtn, self.handleFacebook.bind(self));
        $(document).on('click', self.twitterBtn, self.handleTwitter.bind(self));
        $(document).on('click', self.linkedinBtn, self.handleLinkedin.bind(self));
    },
    handlePrint: function () {
        window.print();
    },
    handleWhatsapp: function (e) {
        var self = this;
        var $target = $(e.currentTarget);
        var shareText = $target.data('share-text');
        var shareLink = $target.data('share-link');
        window.open("https://api.whatsapp.com/send?text=" + shareText + "%20%20" + shareLink, "_blank");
    },
    handleFacebook: function (e) {
        var self = this;
        var $target = $(e.currentTarget);
        var shareText = $target.data('share-text');
        var shareLink = $target.data('share-link');
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareLink, 'facebookwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    },
    handleTwitter: function (e) {
        var self = this;
        var $target = $(e.currentTarget);
        var shareText = $target.data('share-text');
        var shareLink = $target.data('share-link');
        window.open('https://twitter.com/intent/tweet?url=' + shareLink +
            '&text=' + shareText +
            '&hashtags=',
            'twitterwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    },
    handleLinkedin: function (e) {
        var self = this;
        var $target = $(e.currentTarget);
        var shareLink = $target.data('share-link');
        window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + shareLink, 'linkedinwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2);
    }
};

var More = {
    className: ".js-load-more",
    containerClassName: ".js-load-container",
    listClassName: ".js-list-target",
    loadingClassName: ".js-loading",
    hiddenClass: "is-hidden",
    currentPage: null,
    currentInt: null,
    totalInt: null,
    init: function() {
        var self = this;
        self.checkAmount();
        $(document).on("click", self.className, self.handleAjax.bind(self));
    },
    checkAmount: function () {
        var self = this;

        if (typeof amountItems !== 'undefined') {
            if (self.currentPage === null) {
                self.currentPage = 1;
            }

            if (self.currentInt === null) {
                self.currentInt = amountItems;
            }
            if (self.totalInt === null) {
                self.totalInt = totalNewsItem;
            }
            if (self.currentInt >= self.totalInt) {
                $(self.containerClassName).hide();
            }
        }
        
    },
    handleAjax: function(e) {
        var self = this;
        var typeTarget = $(e.currentTarget).data("type");
        var amountResult = $(e.currentTarget).data("result-amount");
        var currentNode = $(e.currentTarget).data("node");
        
        $.ajax({
            method: "GET",
            url: "/umbraco/surface/search/nextpage?type=" + typeTarget + "&currentpage=" + self.currentPage + "&pagesize=" + amountResult + "&parent=" + currentNode,
            beforeSend: function(event) {
                $(self.loadingClassName).removeClass(self.hiddenClass);
            }

            })
            .done(function (result) {
                self.currentPage++;
                self.currentInt += pageSize;
                self.checkAmount();
                $(self.listClassName).append(result);
                $(self.loadingClassName).addClass(self.hiddenClass);
        });
    }
};

window.Parsley
    .addValidator('iban', {
        requirementType: 'string',
        validateString: function (value) {

            var iban = value.replace(/ /g, "").toUpperCase(),
                ibancheckdigits = "",
                leadingZeroes = true,
                cRest = "",
                cOperator = "",
                countrycode, ibancheck, charAt, cChar, bbanpattern, bbancountrypatterns, ibanregexp, i, p;

            // Check for IBAN code length.
            // It contains:
            // country code ISO 3166-1 - two letters,
            // two check digits,
            // Basic Bank Account Number (BBAN) - up to 30 chars
            var minimalIBANlength = 5;
            if (iban.length < minimalIBANlength) {
                return false;
            }

            // Check the country code and find the country specific format
            countrycode = iban.substring(0, 2);
            bbancountrypatterns = {
                "AL": "\\d{8}[\\dA-Z]{16}",
                "AD": "\\d{8}[\\dA-Z]{12}",
                "AT": "\\d{16}",
                "AZ": "[\\dA-Z]{4}\\d{20}",
                "BE": "\\d{12}",
                "BH": "[A-Z]{4}[\\dA-Z]{14}",
                "BA": "\\d{16}",
                "BR": "\\d{23}[A-Z][\\dA-Z]",
                "BG": "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
                "CR": "\\d{17}",
                "HR": "\\d{17}",
                "CY": "\\d{8}[\\dA-Z]{16}",
                "CZ": "\\d{20}",
                "DK": "\\d{14}",
                "DO": "[A-Z]{4}\\d{20}",
                "EE": "\\d{16}",
                "FO": "\\d{14}",
                "FI": "\\d{14}",
                "FR": "\\d{10}[\\dA-Z]{11}\\d{2}",
                "GE": "[\\dA-Z]{2}\\d{16}",
                "DE": "\\d{18}",
                "GI": "[A-Z]{4}[\\dA-Z]{15}",
                "GR": "\\d{7}[\\dA-Z]{16}",
                "GL": "\\d{14}",
                "GT": "[\\dA-Z]{4}[\\dA-Z]{20}",
                "HU": "\\d{24}",
                "IS": "\\d{22}",
                "IE": "[\\dA-Z]{4}\\d{14}",
                "IL": "\\d{19}",
                "IT": "[A-Z]\\d{10}[\\dA-Z]{12}",
                "KZ": "\\d{3}[\\dA-Z]{13}",
                "KW": "[A-Z]{4}[\\dA-Z]{22}",
                "LV": "[A-Z]{4}[\\dA-Z]{13}",
                "LB": "\\d{4}[\\dA-Z]{20}",
                "LI": "\\d{5}[\\dA-Z]{12}",
                "LT": "\\d{16}",
                "LU": "\\d{3}[\\dA-Z]{13}",
                "MK": "\\d{3}[\\dA-Z]{10}\\d{2}",
                "MT": "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
                "MR": "\\d{23}",
                "MU": "[A-Z]{4}\\d{19}[A-Z]{3}",
                "MC": "\\d{10}[\\dA-Z]{11}\\d{2}",
                "MD": "[\\dA-Z]{2}\\d{18}",
                "ME": "\\d{18}",
                "NL": "[A-Z]{4}\\d{10}",
                "NO": "\\d{11}",
                "PK": "[\\dA-Z]{4}\\d{16}",
                "PS": "[\\dA-Z]{4}\\d{21}",
                "PL": "\\d{24}",
                "PT": "\\d{21}",
                "RO": "[A-Z]{4}[\\dA-Z]{16}",
                "SM": "[A-Z]\\d{10}[\\dA-Z]{12}",
                "SA": "\\d{2}[\\dA-Z]{18}",
                "RS": "\\d{18}",
                "SK": "\\d{20}",
                "SI": "\\d{15}",
                "ES": "\\d{20}",
                "SE": "\\d{20}",
                "CH": "\\d{5}[\\dA-Z]{12}",
                "TN": "\\d{20}",
                "TR": "\\d{5}[\\dA-Z]{17}",
                "AE": "\\d{3}\\d{16}",
                "GB": "[A-Z]{4}\\d{14}",
                "VG": "[\\dA-Z]{4}\\d{16}"
            };

            bbanpattern = bbancountrypatterns[countrycode];

            // As new countries will start using IBAN in the
            // future, we only check if the countrycode is known.
            // This prevents false negatives, while almost all
            // false positives introduced by this, will be caught
            // by the checksum validation below anyway.
            // Strict checking should return FALSE for unknown
            // countries.
            if (typeof bbanpattern !== "undefined") {
                ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "");
                if (!ibanregexp.test(iban)) {
                    return false; // Invalid country specific format
                }
            }

            // Now check the checksum, first convert to digits
            ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
            for (i = 0; i < ibancheck.length; i++) {
                charAt = ibancheck.charAt(i);
                if (charAt !== "0") {
                    leadingZeroes = false;
                }
                if (!leadingZeroes) {
                    ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(charAt);
                }
            }

            // Calculate the result of: ibancheckdigits % 97
            for (p = 0; p < ibancheckdigits.length; p++) {
                cChar = ibancheckdigits.charAt(p);
                cOperator = "" + cRest + "" + cChar;
                cRest = cOperator % 97;
            }
            return cRest === 1;




        },
        messages: {
            en: 'Please enter a valid IBAN',
            nl: 'Vul een geldige IBAN in'
        }
    });

var donationSubmitting = false;
var Donate  = {
    className: ".js-donate-form",
    submitClassName: ".js-submit-donate",
    formErrorsClassName: ".js-form-errors",
    hasErrorsClassName: "has-errors",
    hiddenClassName: "is-hidden",
    init: function () {
        var self = this;

        // Validate on submit
        $(document).on("click", self.submitClassName, function (e) {
            // prevent submit before validation
            e.preventDefault();

            // validate contact form
            $(self.className).parsley({
                errorClass: "has-error",
                successClass: "has-success",
                excluded: '[disabled]',
                //errorsContainer: function(field) {
                //    return $(self.className).find(self.formErrorsClassName);
                //},
                errorsWrapper: '<span class="form-field-errors absolute"></span>',
                errorTemplate: "<em></em>"
            }).validate();

            if ($(self.className).parsley().isValid()) {
                console.log("valid");
                self.handleSubmit(self.className);
            } else {
                console.log("invalid");
            }
        });
    },
    handleSubmit: function(form) {

        if (!donationSubmitting) {
            donationSubmitting = true;
            var self = this;
            var serializedData = $(form).serialize();

            $.ajax({
                url: "/umbraco/Surface/Donation/DonationForm",
                context: document.body,
                data: serializedData,
                method: "POST"

            }).done(function (data) {
                donationSubmitting = false;
                $('#donate-form').html(data);
            });
        }
    }
};

var Emergency = {
    className: ".js-emergency",
    cookieName: "emergencyModal",
    closeClassName: ".js-close-modal",
    hiddenClass: "is-hidden",
    visibleClass: "is-visible",
    linkBtnClassName: ".js-link",
    modalContentClassName: ".modal__content",
    init: function() {
        var self = this;
        self.emTitle = $(self.className + " .text--orange");
        self.emContent = $(self.className + " .rte.rte__textblock");
        self.emLink = $(self.className + " .modal__content > a");
        if ($(self.className).length > 0) {

            if (document.cookie.indexOf(self.cookieName) !== -1) {
                /* cookie exists */
                var cookies = "; " + document.cookie;
                var emergencyCookieVal = cookies.split("; " + self.cookieName + "=").pop().split(";").shift(); // cookie value
                var ets = Number(emergencyCookieVal.split("[E]")[1]); // cookie time stamp
                var cts = new Date().setHours(new Date().getHours()); // current time stamp

                if (cts < ets) {
                    /* check cookie content */
                    var cookiehash = emergencyCookieVal.split("[E]")[0]; // cookie content hash
                    var currenthash = self.emTitle.text().substring(0, 2) +
                                      self.emTitle.text().substring(self.emTitle.text().length - 2) + "--" +
                                      self.emTitle.text().length + "|!|" +
                                      self.emContent.text().length + "|!|" +
                                      self.emLink.attr("href").length + "--" +
                                      self.emLink.attr("title"); // current content hash
                    if (cookiehash != currenthash) {
                        /* content changed */
                        $(self.className).addClass(self.visibleClass);
                    }
                } else {
                    /* cookie expired */
                    $(self.className).addClass(self.visibleClass);
                }
            } else {
                /* cookie doesnt exist */
                $(self.className).addClass(self.visibleClass);
            }
        }
        $(document).on("click", self.closeClassName, self.handleClick.bind(self));
        $(document).on("click", self.className, self.handleClickOutside.bind(self));
        $(document).on("click", self.linkBtnClassName, self.handleLinkClick.bind(self));
    },
    setCookie: function(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    handleClick: function() {
        var self = this;
        self.close();
    },
    handleClickOutside: function(event) {
        var self = this;
        var isOutside = !$(event.target).parents(self.modalContentClassName).length && !$(event.target).is(".modal__header") &&
            !$(event.target).is(self.modalContentClassName);

        if (isOutside) {
            self.close();
        }
    },
    handleLinkClick: function () {
        var self = this;
        self.close();
    },
    close: function () {
        var self = this;
        var expireDate = new Date().setHours(new Date().getHours() + 72);

        /* Reset cookie when updated:
         * First and last 2 characters of the title (Did you know?):        Diw?
         * Split with double hyphen:                                        --
         * Length of the title (Did you know?):                             13
         * Split with custom characters:                                    |!|
         * Length of the RTE content (<h1>Important News!<h1>):             23
         * Split with custom characters:                                    |!|
         * Length of the link (/home/news/important):                       20
         * Split with double hyphen:                                        --
         * Title of the link (Go now!)                                      Go now!
         * Split with custom characters (expire time stamp):                |E|
         * Expire timestamp (Now + some hours?):                            1547049579209
        */

        var cookiehash = self.emTitle.text().substring(0, 2) + self.emTitle.text().substring(self.emTitle.text().length - 2) + "--" + self.emTitle.text().length + "|!|" + self.emContent.text().length + "|!|" + self.emLink.attr("href").length + "--" + self.emLink.attr("title");
        $(self.className).removeClass(self.visibleClass);

        console.log(cookiehash + "[E]" + expireDate);
        // Create cookie
        self.setCookie(self.cookieName, cookiehash + "[E]" + expireDate, 1);
    }
};

function fit() {
    iFrameResize({ log: false }, '#myIframe');
}

addEventListener("load", requestAnimationFrame.bind(this, fit))

var Scrolldown = {
    className: '.js-scrolldown',
    scrolledClassName: 'is-hidden',
    init: function () {
        var self = this;
        var elm = document.querySelector(self.className);
        window.onscroll = function () {
            self.handleScroll(elm);
        };
    },
    handleScroll: function (elm) {
        var self = this;
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            elm.classList.add(self.scrolledClassName);
        } else {
            elm.classList.remove(self.scrolledClassName);
        }
    }
};

$(function () {
    var podcasts = document.querySelectorAll(".js-open-podcast");
    if (podcasts.length) {
        podcasts.forEach(function (item) {
            var parent = item.parentElement;
            var podcast = parent.querySelector(".podcast__content__iframe");

            item.addEventListener('click', function () {
                podcast.classList.add("active");
            });
        });
    }

    var iframes = document.querySelectorAll("iframe.gh-fit");
    if (iframes.length) {
        fit();
    }
});

Scrolldown.init();

Emergency.init();
Donate.init();
More.init();
Contact.init();
Article.init();
Faq.init();
Navigation.init();
Socials.init();
Newsletter.init();
News.init();
Petition.init();
Events.init();

//var mailFormplus = $('.mailformplus');
////var isEmpty = mailFormplus.children().is(":empty");

//mailFormplus.on("DOMNodeInserted DOMNodeRemoved", function () {
    
//});
